.wrapper {
    .main {
        padding: 49px 0 32px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            width: 268px;
            font-size: 18px;
            color: #000000;
            line-height: 21px;
            font-weight: bold;
        }

        .icon_google {
            width: 148px;
            height: 36px;
            background-repeat: no-repeat;
            background-image: url('/assets/footer/google.png');
        }

        .icon_facebook {
            width: 140px;
            height: 43px;
            background-repeat: no-repeat;
            background-image: url('/assets/footer/facebook.png');
        }

        .hint {
            width: 368px;
            font-size: 14px;
            font-weight: 500;
            color: #414141;
            line-height: 20px;
        }
    }

    &.mobile, &.tablet {
        .main {
            flex-wrap: wrap;
            padding: 24px 16px 32px;

            .title {
                width: unset;
                margin-bottom: 19px;
            }

            .icon_google {
                margin-bottom: 24px;
            }

            .icon_facebook {
                margin-bottom: 24px;
            }

            .hint {
                width: unset;
            }
        }
    }
}
