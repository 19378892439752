@mixin text-overflow($line: 1, $line-height: 17px) {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    max-height: $line-height * $line;
}

@mixin transition($option: all 0.2s ease-in-out) {
    -webkit-transition: $option;
    -moz-transition: $option;
    -o-transition: $option;
    transition: $option;
}
