.modalQuestion {
    .modal-header {
        border-radius: 8px;
        border: 1px solid white;
    }
    .modalQuestion_header {
        padding: 0;
        .close {
            display: flex;
            cursor: pointer;
            margin: 14px 14px auto auto;
        }
        > button {
            padding: 0 !important;
            margin: 0 !important;
        }
        button[type='submit'] {
            font-size: 14px;
        }
    }
    // .popup {
    //     form {
    //         .form_answer {
    //             padding: 0 24px 24px 24px;
    //             &__header {
    //                 display: block;
    //                 .rules {
    //                     margin-top: 8px;
    //                 }
    //             }
    //             &__content {
    //                 .upload {
    //                     span {
    //                         strong {
    //                             display: flex;
    //                         }
    //                     }
    //                 }
    //                 .images {
    //                     width: calc((100% - 56px) / 4);

    //                     .image_review {
    //                         .image_upload {
    //                             width: 64px;
    //                             height: 64px;
    //                         }
    //                     }
    //                 }
    //             }
    //             .sendNotification {
    //                 display: block;
    //                 .phone_number {
    //                     width: 100%;
    //                 }
    //                 .otp {
    //                     width: 100%;
    //                     input {
    //                         width: 100%;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}
