.wrapper {
    color: #ffffff;
    padding: 40px 0 44px;

    background: #024eb1;

    .main {
        padding: 0;
        .wrapper_content {
            display: flex;
            padding-bottom: 24px;
            justify-content: space-between;
            &:not(:last-child) {
                border-bottom: 1px solid rgba(20, 121, 255, 0.7);
            }
            .col {
                padding: 0;
                max-width: 20%;
                &.col_bct {
                    max-width: unset;
                }
                .bold {
                    font-size: 14px;
                    font-weight: bold;
                }
                .title {
                    font-size: 14px;
                    line-height: 20px;

                    &.mb_4 {
                        margin-bottom: 4px;
                    }

                    &.mt_11 {
                        margin-top: 6px;
                    }

                    .bold {
                        font-size: 14px;
                        font-weight: bold;
                    }

                    .mail {
                        color: #ffffff;
                        font-weight: 500;
                        line-height: 14px;

                        text-decoration: none;
                    }

                    .hotline {
                        margin-top: 9px;

                        .tel {
                            font-size: 20px;
                            font-weight: bold;

                            .phone_number {
                                color: #ffffff;
                                text-decoration: none;
                            }
                        }
                    }
                }
                .text {
                    display: flex;
                    font-weight: 400;
                    line-height: 20px;
                    span {
                        padding: 0 12px;
                    }
                }
            }
        }
        .wrapper_bct {
            padding-top: 28px;
            .col_bct {
                img {
                    width: 129px;
                    height: 48px;
                }
            }
        }
    }

    &.mobile,
    &.tablet {
        padding: 36px 0 16px;

        .main {
            .wrapper_content {
                flex-wrap: wrap;
                margin: 0 16px;
                padding-bottom: 0px;

                .col {
                    padding: 0;
                    flex: 1 1 100%;
                    max-width: 80%;
                    margin-bottom: 24px;

                    .text {
                        display: block;
                    }

                    .title {
                    }
                    .email {
                        margin-bottom: 4px;
                    }
                    &.col_bct {
                        max-width: unset;
                    }
                }
            }
        }
    }
}
