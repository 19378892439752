@use '@Styles/mixin';

.layout {
    min-height: 100vh;
    position: relative;

    display: flex;
    flex-direction: column;

    .layout_navbar {
        .navbar_link {
            color: #000;
        }
    }

    .search_form {
        width: 300px;
        margin-left: 30px;
        position: relative;

        .form {
            .label {
                width: 100%;
                display: none;
            }

            .input {
                width: 100%;
                background-color: #fff;
            }
        }

        .suggestion_title {
            margin: 0px;
            display: flex;
            font-size: 12px;
            padding: 5px 10px;
            align-items: center;
            justify-content: space-between;
        }

        .search_suggestion {
            left: 0px;
            top: 100%;
            z-index: 1000;
            min-width: 100%;
            position: absolute;
            background-color: #fff;
            box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);

            .suggestion_result {
                height: 100px;
                display: flex;
                text-align: center;
                padding: 20px 20px;
                align-items: center;
                justify-content: center;
            }

            a {
                display: block;
                padding: 5px 10px;

                .post {
                    display: flex;
                    align-items: center;

                    .img {
                        width: 50px;
                        height: 50px;
                        margin-right: 10px;
                    }

                    .title {
                        margin: 0px;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .categories {
        height: 46px;
        background-color: rgba(86, 61, 124, 0.15);

        .item {
            margin-right: 20px;

            .link {
                color: #000;
                padding: 5px 0px;
                font-weight: 600;
            }
        }
    }

    .categories_scroll_snap {
        display: flex;
        height: 116px;
        overflow-x: auto;
        margin: 30px auto 0px auto;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }

        a {
            display: flex;
            height: 100px;
            flex: 0 0 100px;
            margin: 0px 10px;
            max-width: 100px;
            text-align: center;
            align-items: center;
            border-radius: 30px;
            justify-content: center;
            scroll-snap-align: start;
            background-color: rgba(86, 61, 124, 0.15);
        }
    }

    .bottom_page {
        right: 65px;
        bottom: 17px;
        position: fixed;
    }

    .loading {
        opacity: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: rgba(9, 30, 66, 0.2);

        @include mixin.transition(0.3s);

        &.active {
            top: 0px;
            opacity: 1;
            z-index: 1000000;
        }

        img {
            width: 25px;
            height: 25px;
            transform-origin: center;
            animation-name: spinning;
            animation-duration: 0.4s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }
    }
}

@keyframes spinning {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
