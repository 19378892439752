@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-rater/lib/react-rater.css';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import './root.scss';

@import './text.scss';

html {
    scroll-behavior: smooth;
}

body {
    transition: padding-bottom 0.2s linear;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

b {
    font-weight: bold;
}

.overflow-hidden {
    height: 100%;
    position: relative;
    touch-action: none;
    -ms-touch-action: none;
    overflow: hidden !important;
}

.ph-item {
    border: none;
    padding: 0px;
}

.container {
    @media (max-width: 768px) {
        max-width: 100%;
    }

    @media (min-width: 769px) {
        min-width: 1180px;
    }

    @media (min-width: 1180px) {
        max-width: 1180px;
    }
}

.react-rater-star {
    font-size: 20px;
    margin-right: 8px;

    &::before {
        background: -webkit-linear-gradient(135deg, #ffcd5e 0%, #ea9a22 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &.is-active,
    &.will-be-active {
        background: -webkit-linear-gradient(135deg, #ffcd5e 0%, #ea9a22 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.react-rater {
    div {
        margin-right: 4px;

        .react-rater-star {
            margin-right: 0;
            font-size: 20px;
        }
    }
}

.custom-select {
    border: 0px;
    padding: 0px;
    min-height: 48px;

    .custom-select__control {
        padding: 0px 9px;
        min-height: 48px;
        border-radius: 8px;
        border: 1px solid #ebecf0;
        // background-color: var(--light-4);

        &:hover {
            border: 1px solid #ebecf0;
        }

        &--is-focused {
            box-shadow: none;
            border: 1px solid #1479ff !important;
        }
    }

    .custom-select__value-container {
        height: 42px;
        padding: 0px var(--space-16);
        margin: 0px calc(var(--space-4) * -1);

        .custom-select__single-value {
            font-size: 16px;
        }
    }

    .custom-select__indicators {
        margin: 0px -4px;
        padding: 0px 8px;

        .custom-select__clear-indicator {
            width: 20px;
            height: 20px;
            display: flex;
            margin: 0px 0px;
            position: relative;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.2);

            &:before,
            &:after {
                width: 12px;
                height: 1px;
                content: '';
                display: block;
                position: absolute;
                transform: rotate(45deg);
                transform-origin: center;
                background-color: var(--white);
            }

            &:after {
                transform: rotate(-45deg);
            }

            svg {
                display: none;
            }
        }

        .custom-select__indicator-separator {
            display: none;
        }

        .custom-select__dropdown-indicator {
            margin: 0px 0px;
            background-size: 24px 24px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(/assets/tra-cuu/phong-kham/dropdown_icon.png);

            svg {
                display: none;
            }
        }
    }

    .custom-select__menu {
        .custom-select__menu-list {
            background: #ffffff;
            border: 1px solid #f5f6f7;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);

            .custom-select__option {
                font-size: 16px;
            }

            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(204, 204, 204, 0);
            }
            &:hover {
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(204, 204, 204, 1);
                }
            }
        }
    }

    .custom-select__placeholder {
        font-size: 16px;
        color: #37383a;
    }
}

.fadeIn {
    opacity: 0;
    animation-name: fade;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.placeholder {
    overflow: hidden;
    position: relative;

    &:before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: 1;
        width: 500%;
        content: '';
        z-index: 1;
        margin-left: -250%;
        position: absolute;
        pointer-events: none;
        animation: placeholder 0.8s linear infinite;
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, 0.09) 50%, rgba(255, 255, 255, 0) 54%)
            50% 50%;
    }
}

@keyframes placeholder {
    0% {
        transform: translate3d(-30%, 0, 0);
    }
    100% {
        transform: translate3d(30%, 0, 0);
    }
}
