.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;

    &.desktop {
        width: 1182px;
    }
}