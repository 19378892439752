.wrapper {
    overflow: hidden;
    .modal {
        width: 100%;
        height: 100%;
        display: flex;
        margin: 0px auto;
        max-width: unset;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.3);

        .content {
            border: 0px;
            max-width: 350px;
            border-radius: 8px;
            padding: 32px 16px 16px 16px;

            .body {
                padding: 0px;
                display: flex;
                align-items: center;
                flex-direction: column;

                .icon {
                    margin-bottom: 24px;
                }

                .title {
                    font-size: 20px;
                    color: #000000;
                    font-weight: bold;
                    line-height: 24px;
                    text-align: center;
                    margin-bottom: 8px;
                }

                .text {
                    font-size: 14px;
                    color: #414141;
                    padding: 0px 24px;
                    line-height: 21px;
                    text-align: center;
                    font-weight: normal;
                    margin-bottom: 24px;
                }

                .button {
                    width: 100%;
                    border: 0px;
                    color: #fff;
                    outline: none;
                    padding: 15px;
                    display: block;
                    border-radius: 8px;
                    background-color: #1479FF;
                }
            }
        }
    }
}
