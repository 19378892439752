.form_answer {
    padding: 24px 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

    &__header {
        display: flex;
        justify-content: space-between;
        color: #37383a;
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
        }
        .rules {
            cursor: pointer;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            img {
                margin-right: 5px;
            }
        }
    }

    &__content {
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #d5dce3;
        margin-top: 16px;

        textarea {
            margin: 16px 16px 24px 16px;
            outline: none;
            resize: none;
            width: calc(100% - 16px);
            background: #ffffff;

            border: none;
            overflow: auto;
            outline: none;

            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }

        .input_feedback {
            font-size: 14px;
            color: #ff371d;
            margin-bottom: 8px;
        }
        .input_feedback_questionContent {
            margin-left: 16px;
        }

        .upload {
            height: 53px;
            display: flex;
            padding-left: 16px;
            position: relative;
            padding-right: 8px;
            align-items: center;
            border-top: 1px solid #eff0f3;

            input[type='file'] {
                width: 56px;
                position: absolute;
                opacity: 0;
                left: 0;
                cursor: pointer;
            }
            img {
                cursor: pointer;
                margin-right: 8px;
            }
            span {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #37383a;

                strong {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                    color: #808890;
                }
            }
        }
        .images {
            display: flex;
            flex-direction: row;
            margin: 0px 16px 16px 16px;

            .image_review {
                position: relative;
                margin-right: 8px;
                width: 80px;
                .image_upload {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    object-position: center;
                }
                img {
                    border-radius: 8px;
                    margin-left: 0px;
                }
                .image_remove {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-top: 4px;
                }
            }
        }
    }
    //
    .sendNotification {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .input_feedback {
            font-size: 14px;
            color: #ff371d;
            margin-top: 8px;
        }

        input {
            border: 1px solid #d5dce3;
            box-sizing: border-box;
            border-radius: 8px;
            height: 40px;
            outline: none;
            font-size: 14px;

            color: #808890;
        }

        .phone_number {
            width: 49%;
            span {
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
            }

            .input_group {
                display: flex;
                align-items: center;
                border: 1px solid #d5dce3;
                border-radius: 8px;
                margin-top: 16px;

                span {
                    height: 45px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid #d5dce3;
                    font-weight: normal;
                }
                input[type='tel'] {
                    flex: 1;
                    border: none;
                    padding-left: 8px;
                    position: relative;
                }
                .send_otp,
                .duration {
                    background: #907dff;
                    border-radius: 4px;
                    width: 84px;
                    height: 37px;

                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;

                    text-align: center;
                    color: #ffffff;
                    margin-right: 3px;

                    cursor: pointer;
                }
                .duration {
                    width: 92px;
                }
            }
        }

        .otp {
            width: 49%;
            input[type='text'] {
                width: 100%;
                margin-top: 16px;
                height: 45px;
                position: relative;
                padding-left: 8px;
            }
        }
    }
    button {
        margin-top: 24px;
        width: 120px;
        height: 48px;
        font-size: 14px;
        background: #1479ff;
        border-radius: 8px;
        color: white;
        border: none;
        font-weight: bold;
    }
}
.form_question_page_detail {
    margin-bottom: 24px;
    .form_answer {
        padding: 24px 16px !important;
    }

    button {
        width: 100%;
    }
}
.popup {
    .form_answer {
        padding: 0 24px 24px 24px;
        margin-bottom: 0px;

        &__header {
            display: block;
            .rules {
                margin-top: 8px;
            }
        }
        &__content {
            .upload {
                span {
                    strong {
                        display: flex;
                    }
                }
            }
            .images {
                width: calc((100% - 56px) / 4);

                .image_review {
                    .image_upload {
                        width: 64px;
                        height: 64px;
                    }
                }
            }
        }
        .sendNotification {
            display: block;
            .phone_number {
                width: 100%;
            }
            .otp {
                width: 100%;
                input {
                    width: 100%;
                }
            }
        }
    }
}

.mobile {
    button {
        width: 100%;
    }
}
