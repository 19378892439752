@use '@Styles/mixin';

.wrapper {
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
    padding: 8px 64px;
    background-color: #fff;
    position: sticky !important;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);

    .icon {
        width: 8px;
        height: auto;
    }

    .brand {
        margin-top: 3px;
        margin-right: 48px;

        cursor: pointer;
        display: inline-block;

        img {
            width: 113px;
            height: 36px;
        }
    }

    .menu {
        width: 100%;
        height: 52px;
        display: flex;
        width: 1314px;
        margin: 0px auto;
        min-width: 1314px;
        justify-content: flex-start;

        .left {
            display: flex;

            .brand {
                margin-top: 3px;
                margin-right: 48px;

                cursor: pointer;
                display: inline-block;

                img {
                    width: 133px;
                    height: 36px;
                }
            }

            .menu_item {
                display: block;
                margin-top: 7px;
                color: #37383a;
                text-decoration: none;

                &:not(:last-child) {
                    margin-right: 24px;
                }

                cursor: pointer;

                .title {
                    font-size: 13px;
                    color: #37383a;
                    font-weight: bold;

                    display: flex;
                    align-items: center;

                    .icon {
                        margin-left: 3px;
                    }
                }

                .sub_title {
                    color: #696969;
                    font-size: 12px;
                }

                .dropdown_menu {
                    width: 400px;
                    top: 15px !important;
                    box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);

                    [class*='menu-item'] {
                        padding: 8px 0;
                    }
                    .dropdown_menu_item {
                        &.all_service {
                            padding-top: 16px;
                            border-top: 1px solid #f5f7fa;
                            .item_content {
                                .item_title {
                                    font-size: 14px;
                                    color: #1479ff;
                                    line-height: 18px;
                                }
                            }
                        }
                    }
                    &_item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        padding: 12px 16px;
                        text-decoration: none;
                        transition: all 0.1s ease-in-out;
                        // &:not(:last-child) {
                        //     margin-bottom: 8px;
                        // }

                        &:hover {
                            background: #f5f7fa;

                            .item_icon {
                                color: #dfe1e6;
                            }
                        }

                        .item_content {
                            width: 270px;
                            margin-left: 16px;

                            display: flex;
                            flex-direction: column;
                            .item_title {
                                font-size: 13px;
                                color: #37383a;
                                line-height: 14px;
                                font-weight: bold;
                                text-decoration: none;
                            }

                            .item_sub_title {
                                font-size: 13px;
                                color: #37383a;
                                font-weight: 500;
                                line-height: 18px;
                                text-decoration: none;
                            }
                        }

                        .item_icon {
                            width: 24px;
                            height: 24px;
                            color: #ffffff;
                            margin-left: 18px;
                            transition: all 0.1s ease-in-out;
                        }
                    }
                }

                &.disabled {
                    opacity: 0.5;
                }
            }
        }

        .right {
            display: flex;
            margin-left: auto;
            position: relative;
            align-items: center;

            .icon_search {
                top: 4px;
                left: -24px;
                width: 24px;
                height: 24px;
                position: absolute;
                object-fit: cover;
                object-position: center;

                &.auth {
                    top: unset;
                    left: unset;
                    position: unset;
                    cursor: pointer;
                    object-fit: cover;
                    margin-right: 24px;
                    object-position: center;
                }
            }

            .dropdown {
                border-radius: 8px;

                &:not(:last-child) {
                    margin-right: 18px;
                }

                .dropdown_menu {
                    left: 8px !important;
                    top: 24px !important;
                    box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);

                    &.access_menu {
                        top: 20px !important;
                    }
                }

                .with_notification {
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                    position: relative;

                    .icon_notification {
                        width: 32px;
                        height: 32px;
                        object-fit: cover;
                        object-position: center;
                    }

                    .notification_number {
                        top: 0;
                        right: -5px;
                        position: absolute;

                        width: auto;
                        height: 16px;
                        padding: 0 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        background: #ff371d;
                        border-radius: 8px;
                        border: 1px solid #ffffff;

                        font-size: 10px;
                        color: #ffffff;
                        font-weight: bold;
                    }
                }
            }

            .avatar_group {
                width: 40px;
                height: 40px;
                cursor: pointer;
                margin-left: 6px;
                position: relative;
                border-radius: 50%;

                .avatar {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                .icon_setting {
                    width: 16px;
                    height: 16px;
                    object-fit: cover;
                    object-position: center;

                    bottom: 0;
                    right: -2px;
                    position: absolute;
                }
            }

            .right_dropdown {
                margin-top: 7px;

                .btn_dropdown {
                    font-size: 13px;
                    cursor: pointer;

                    display: flex;
                    align-items: center;

                    .icon {
                        margin-left: 4px;
                    }
                }
            }

            .authenticate_dropdown {
                .login_menu {
                    top: 5px !important;
                    box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);

                    [class*='menu-item'] {
                        padding: 24px 16px;
                        border-radius: 8px;
                    }
                }

                .authenticate {
                    margin-left: 17px;

                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .btn_authenticate {
                        height: 32px;
                        font-size: 13px;
                        cursor: pointer;
                        color: #ffffff;
                        font-weight: bold;
                        margin-bottom: 3px;

                        padding: 0 12px;
                        border-radius: 8px;
                        background: #1e6dd5;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .help_text {
                        font-size: 12px;
                    }
                }
            }

            .notifications {
                .body {
                    height: 100%;
                    display: flex;
                    max-height: calc(100vh - 300px);
                }

                .schedule_wrapper {
                    overflow: hidden;

                    [class*='schedule_notifications'] {
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: rgba(204, 204, 204, 0);
                        }

                        &:hover {
                            &::-webkit-scrollbar-thumb {
                                background-color: rgba(204, 204, 204, 1);
                            }
                        }
                    }
                }
            }
        }
    }

    &.mobile,
    &.tablet {
        padding: 0px;

        .menu {
            width: 100%;
            display: flex;
            min-width: 100%;
            padding: 8px 16px;
            align-items: center;
            justify-content: space-between;

            .brand {
                margin: 0px;

                img {
                    width: 113px;
                    height: 36px;
                }
            }

            .toggle {
                border: none;
                outline: none;
                background-color: transparent;
            }
        }
    }
}

.overlay {
    top: 0px;
    left: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    animation-name: fadeIn;
    animation-duration: 0.2s;
    background-color: rgba(0, 0, 0, 0.4);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.sidebar {
    top: 0px;
    height: 100%;
    z-index: 1000;
    overflow: auto;
    position: fixed;
    right: calc(-100% - 1px);
    background-color: #fff;
    width: calc(100% - 32px);
    transition: all 0.2s linear;

    &.show {
        right: 0px;

        .overlay {
            display: block;
        }
    }

    .section {
        z-index: 100;
        position: relative;
        padding: 16px 24px;

        &:not(:last-child) {
            border-bottom: 1px solid #ebecf0;
        }

        .title {
            font-size: 16px;
            color: #000000;
            line-height: 20px;
            margin-bottom: 8px;

            &.disabled {
                opacity: 0.5;
            }
        }

        .list {
            margin: 0px;
            padding-left: 16px;
            list-style: none inside;

            .item {
                .link {
                    display: flex;
                    font-size: 14px;
                    font-weight: 400;
                    color: #37383a;
                    line-height: 18px;
                    align-items: center;
                    text-decoration: none;

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 16px;
                    }

                    .badge {
                        height: 16px;
                        display: flex;
                        min-width: 16px;
                        font-size: 10px;
                        color: #ffffff;
                        margin-left: 8px;
                        padding: 0px 3px;
                        font-weight: bold;
                        text-align: center;
                        border-radius: 8px;
                        align-items: center;
                        justify-content: center;
                        background-color: #ff371d;
                    }
                }
                .activeLink {
                    color: #37383a;
                    font-weight: bold;
                    background: unset;
                    pointer-events: none;
                }

                &:not(:last-child) {
                    margin-bottom: 16px;
                }
                &.all_item {
                    .link {
                        font-weight: 700;
                        color: #1479ff;
                    }
                }
            }
        }

        &.authentication {
            top: 0px;
            left: 0px;
            width: 100%;
            height: 83px;
            z-index: 100000;
            position: sticky;
            background-color: #fff;

            .btn_login {
                border: none;
                outline: none;
                font-size: 13px;
                color: #ffffff;
                padding: 8px 16px;
                font-weight: bold;
                line-height: 14px;
                border-radius: 8px;
                margin-bottom: 4px;
                background-color: #1e6dd5;
            }

            .text {
                font-size: 12px;
                color: #696969;
                line-height: 18px;
            }

            .toggle {
                top: 28px;
                right: 28px;
                border: none;
                outline: none;
                position: absolute;
                background-color: transparent;
            }

            .user {
                display: flex;
                align-items: center;

                .summary {
                    margin-right: 10px;

                    .avatar {
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                    }
                }

                .actions {
                    margin: 0px;
                    padding: 0px;
                    display: flex;
                    align-items: center;
                    list-style: none inside;

                    .item {
                        margin: 0px 10px;

                        .link {
                            position: relative;

                            .icon {
                                width: 32px;
                                height: 32px;
                            }

                            .badge {
                                top: -50%;
                                height: 14px;
                                display: block;
                                font-size: 10px;
                                color: #ffffff;
                                padding: 0px 4px;
                                line-height: 14px;
                                font-weight: bold;
                                border-radius: 8px;
                                text-align: center;
                                position: absolute;
                                right: calc(-50% + 8px);
                                border: 1px solid #fff;
                                background-color: #ff371d;
                            }
                        }
                    }
                }
            }
        }

        &.featured {
            .title {
                display: block;
                margin-bottom: 0px;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }

        &.app {
            .title {
                strong {
                    color: #1e6dd5;
                }
            }

            .store {
                a {
                    margin-right: 8px;
                }
            }
        }
    }
}
