.wrapper {
    padding: 40px 0 44px;
    color: #ffffff;
    background: #0254c0;

    .main {
        display: flex;
        justify-content: space-between;
        padding: 0;
        .col {
            display: flex;
            flex-direction: column;

            .logo {
                width: 92px;
                height: 30px;
                margin-bottom: 15px;
                background-repeat: no-repeat;
            }

            .title {
                font-size: 14px;
                margin-top: 7px;
                line-height: 18px;
                font-weight: bold;
                margin-bottom: 21px;
            }

            .connect {
                margin: 7px;
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
                margin-bottom: 14px;
            }

            .row {
                cursor: pointer;
                font-size: 14px;
                color: #ffffff;
                font-weight: 500;
                line-height: 14px;
                position: relative;
                text-decoration: none;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                &.in_coming {
                    pointer-events: none;

                    color: rgba(255, 255, 255, 0.7);
                    &::after {
                        content: 's\1EAFp ra m\1EAFt';

                        margin-left: 4px;
                        padding: 3px 6px 1px;
                        display: inline-block;
                        text-transform: uppercase;

                        font-size: 10px;
                        font-weight: 700;
                        border-radius: 5px;
                        background-color: #3dc7ff;
                    }
                }
            }

            .social_network {
                .icon_item {
                    width: 36px;
                    height: 36px;
                    margin-right: 16px;
                    display: inline-block;
                    background-repeat: no-repeat;

                    img {
                        width: 36px;
                        height: 36px;
                    }
                }
            }

            .download_title {
                font-size: 14px;
                margin-top: 40px;
                font-weight: bold;
                line-height: 18px;
            }

            .download_app {
                margin-top: 16px;

                .icon_download {
                    cursor: pointer;
                    margin-right: 8px;
                    display: inline-block;
                    background-repeat: no-repeat;

                    img {
                        height: 32px;
                        width: 108px;
                    }
                }
            }
        }
    }

    &.mobile,
    &.tablet {
        padding: 36px 0;

        .main {
                padding: 0 16px;

            flex-wrap: wrap;

            .logo {
                width: 100%;
                flex: 1 1 100%;
                margin-bottom: 24px;
                object-fit: contain;
                object-position: left;
                background-repeat: no-repeat;
            }

            .col {
                flex: 1 1 calc(50%);
                margin-bottom: 36px;
                &.col_mobile {
                    width: 100%;
                    flex: 1 1 100%;
                }

                &:last-child {
                    flex: 1 1 100%;
                    margin-bottom: 0;
                }

                .title {
                    margin: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
